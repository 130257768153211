@tailwind base;
@tailwind components;
@tailwind utilities;
/* 
body {
    background: rgb(243, 234, 241) !important;
} */

.dropdown:hover .dropdown-menu {
    display: block;
}
